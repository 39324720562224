<template>
  <input v-model="value" class="phone-input" type="text" />
</template>

<script>
export default {
  name: 'SmPhoneInput',

  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false,
    },
  },
};
</script>

<style lang="scss">
.phone-input {
  max-width: 100%;
  height: 40px;
  padding: 0 20px;

  font-size: 20px;

  border: 1px solid var(--gray);
}
</style>
